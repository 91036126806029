
<div class="page-customized">
    <v-progress-linear
        v-if="overlay"
        absolute
        indeterminate
    ></v-progress-linear>

    <app-bar
        :title="event.exists() ? t('event_title_update') : t('event_title_create')"
        back="/events"
    />

    <v-layout class="mt-n8">
        <v-card
            width="100%"
            color="transparent"
            elevation="0"
            class="pb-8"
        >
            <v-card-text class="pb-0">
                <v-form class="mt-6">
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('event_label_name') }}</div>
                        <div class="horizontal-input">
                            <v-text-field
                                v-model="event.name"
                                type="text"
                                required
                                outlined
                                dense
                                :error-messages="form.errors.get('name')"
                            ></v-text-field>
                        </div>
                        <div class="horizontal-actions">
                            <edit-translations-modal
                                v-model="event.name"
                                :items="event.translations['name']"
                                @touch="(v) => event.translations['name'] = v"
                            ></edit-translations-modal>
                        </div>
                    </div>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('event_label_foreword') }}</div>
                        <div class="horizontal-input">
                            <v-textarea
                                v-model="event.foreword"
                                required
                                outlined
                                dense
                                :error-messages="form.errors.get('foreword')"
                            ></v-textarea>
                        </div>
                        <div class="horizontal-actions">
                            <edit-translations-modal
                                v-model="event.foreword"
                                :items="event.translations['foreword']"
                                @touch="(v) => event.translations['foreword'] = v"
                                text
                            ></edit-translations-modal>
                        </div>
                    </div>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('event_label_description') }}</div>
                        <div class="horizontal-input">
                            <tinymce
                                key="description"
                                v-model="event.description"
                            ></tinymce>
                        </div>
                        <div class="horizontal-actions">
                            <edit-translations-modal
                                v-model="event.description"
                                :items="event.translations['description']"
                                @touch="(v) => event.translations['description'] = v"
                                editor
                            ></edit-translations-modal>
                        </div>
                    </div>
                    <div v-if="event.creator !== 'trainee'" class="form-group-horizontal-cols">
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('general_label_created_by') }}</div>
                            <div class="horizontal-input">
                                <v-select
                                    v-model="event.creator"
                                    :items="communityCreators()"
                                    required
                                    outlined
                                    append-icon="mdi-chevron-down"
                                    dense
                                    :error-messages="form.errors.get('creator')"
                                ></v-select>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('event_label_associates') }}</div>
                            <div class="horizontal-input">
                                <v-select
                                    v-model="event.associates"
                                    :items="associates()"
                                    required
                                    outlined
                                    append-icon="mdi-chevron-down"
                                    dense
                                    multiple
                                    :error-messages="form.errors.get('associates')"
                                ></v-select>
                            </div>
                        </div>
                    </div>
                    <div class="form-group-horizontal-cols">
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('event_label_photo') }}</div>
                            <div class="horizontal-input">
                                <v-file-input
                                    v-model="event.photo"
                                    required
                                    outlined
                                    prepend-icon=""
                                    prepend-inner-icon="mdi-paperclip"
                                    :append-outer-icon="event.photoUrl ? 'mdi-eye-outline' : ''"
                                    dense
                                    :error-messages="form.errors.get('photo')"
                                    @click:append-outer="viewPhoto()"
                                    @click:clear="event.photo = undefined; photoForm.errors.clear('photo')"
                                ></v-file-input>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('event_label_badges') }}</div>
                            <div class="horizontal-input">
                                <v-autocomplete
                                    v-model="event.badgeIds"
                                    :items="badges"
                                    required
                                    outlined
                                    append-icon="mdi-chevron-down"
                                    dense
                                    multiple
                                    :error-messages="form.errors.get('badges')"
                                ></v-autocomplete>
                            </div>
                        </div>
                    </div>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('event_label_location') }}</div>
                        <div class="horizontal-input">
                            <google-autocomplete
                                v-model="event.location"
                                id="google-address"
                                @change="updateAutocompleteText"
                                @placechanged="getAddressData"
                                dense
                                outlined
                                fulled
                                types="(cities)"
                                placeholder="Type city"
                                :error-messages="form.errors.get('location_coordinates')"
                                :disabled="eventTypeVirtual"
                            />
                        </div>
                    </div>
                    <v-switch
                        class="ma-0"
                        v-model="eventTypeVirtual"
                        :label="t('event_label_virtual')"
                    ></v-switch>
                    <v-switch
                        class="ma-0"
                        v-model="event.private"
                        :label="t('event_label_private')"
                    ></v-switch>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('event_label_repeat_type') }}</div>
                        <div class="horizontal-input">
                            <v-select
                                v-model="event.repeatType"
                                :items="repeatTypes()"
                                required
                                outlined
                                append-icon="mdi-chevron-down"
                                dense
                                :error-messages="form.errors.get('repeat_type')"
                            ></v-select>
                        </div>
                    </div>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('event_label_start_at') }}</div>
                        <div class="horizontal-input">
                            <v-text-field
                                v-model="startAt"
                                type="datetime-local"
                                required
                                outlined
                                dense
                                :error-messages="form.errors.get('start_at')"
                            ></v-text-field>
                        </div>
                    </div>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('event_label_end_at') }}</div>
                        <div class="horizontal-input">
                            <v-text-field
                                v-model="endAt"
                                type="datetime-local"
                                required
                                outlined
                                dense
                                :error-messages="form.errors.get('end_at')"
                            ></v-text-field>
                        </div>
                    </div>
                    <div
                        v-if="event.repeatType !== 'one_time'"
                        class="form-group-horizontal"
                    >
                        <div class="horizontal-label">{{ t('event_label_repeat_count') }}</div>
                        <div class="horizontal-input">
                            <v-text-field
                                v-model="event.repeatCount"
                                v-maska="'######'"
                                type="number"
                                step="1"
                                min="2"
                                required
                                outlined
                                dense
                            ></v-text-field>
                        </div>
                    </div>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('event_label_goal') }}</div>
                        <div class="horizontal-input">
                            <v-btn-toggle
                                v-model="event.targetType"
                                active-class="primary"
                                class="my-1"
                                dense
                                mandatory
                            >
                                <v-btn
                                    value="none"
                                    class="exs mr-3"
                                    small
                                >{{ targetTypeTitle('none') }}</v-btn>
                                <v-btn
                                    value="distance"
                                    class="exs mr-3"
                                    small
                                >{{ targetTypeTitle('distance') }}</v-btn>
                                <v-btn
                                    value="duration"
                                    class="exs"
                                    small
                                >{{ targetTypeTitle('duration') }}</v-btn>
                            </v-btn-toggle>
                            <v-input
                                type="hidden"
                                :error-messages="form.errors.get('target_type')"
                            ></v-input>
                        </div>
                    </div>
                    <template v-if="event.targetType !== 'none'">
                        <div class="form-group-horizontal" v-if="event.targetType === 'distance'">
                            <div class="horizontal-label">{{ t('event_label_distance') }}</div>
                            <div class="horizontal-input">
                                <v-text-field
                                    v-model="event.distance"
                                    v-maska="'######'"
                                    type="number"
                                    step="1"
                                    required
                                    outlined
                                    dense
                                    :error-messages="form.errors.get('distance')"
                                ></v-text-field>
                            </div>
                        </div>
                        <div class="form-group-horizontal" v-if="event.targetType === 'duration'">
                            <div class="horizontal-label">{{ t('event_label_duration') }}</div>
                            <div class="horizontal-input">
                                <v-text-field
                                    v-model="event.duration"
                                    required
                                    outlined
                                    dense
                                    v-maska="'##:##:##.###'"
                                    :error-messages="form.errors.get('duration')"
                                ></v-text-field>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('event_label_stroke') }}</div>
                            <div class="horizontal-input">
                                <v-select
                                    v-model="event.strokeId"
                                    :items="strokes"
                                    required
                                    outlined
                                    append-icon="mdi-chevron-down"
                                    dense
                                    :error-messages="form.errors.get('stroke_id')"
                                ></v-select>
                            </div>
                        </div>
                    </template>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('event_label_reward') }}</div>
                        <div class="horizontal-input">
                            <v-textarea
                                v-model="event.reward"
                                required
                                outlined
                                dense
                                :error-messages="form.errors.get('reward')"
                            ></v-textarea>
                        </div>
                        <div class="horizontal-actions">
                            <edit-translations-modal
                                v-model="event.reward"
                                :items="event.translations['reward']"
                                @touch="(v) => event.translations['reward'] = v"
                                text
                            ></edit-translations-modal>
                        </div>
                    </div>
                    <div class="form-group-horizontal" v-if="event.photos && event.photos.length">
                        <v-sheet
                            v-for="(photo, i) in event.photos"
                            :key="photo"
                            color="white"
                            elevation="0"
                            height="200"
                            width="200"
                            class="float-left mr-8 mb-8 bordered position-relative"
                        >
                            <v-img
                                :lazy-src="photo"
                                :src="photo"
                                max-height="150"
                                max-width="150"
                                class="mx-auto mt-6"
                            ></v-img>
                            <confirm-modal
                                :title="t('file_manager_label_confirm_delete')"
                                @agree="event.delPhoto(i)"
                            >
                                <template #default="{ on, attrs }">
                                    <v-btn
                                        plain
                                        icon
                                        absolute
                                        top
                                        right
                                        class="mt-n5 mr-n5"
                                        v-on="on"
                                        v-bind="attrs"
                                    ><v-icon color="customized-red">mdi-close</v-icon></v-btn>
                                </template>
                            </confirm-modal>
                            <v-btn
                                v-if="i !== 0"
                                @click="event.updatePhotoNumber(i, (i - 1))"
                                plain
                                icon
                                absolute
                                bottom
                                left
                                class="mb-n5 ml-n5"
                            ><v-icon color="customized-primary">mdi-arrow-left</v-icon></v-btn>
                            <v-btn
                                v-if="i !== event.photos.length - 1"
                                @click="event.updatePhotoNumber(i, (i + 1))"
                                plain
                                icon
                                absolute
                                bottom
                                right
                                class="mb-n5 mr-n5"
                            ><v-icon color="customized-primary">mdi-arrow-right</v-icon></v-btn>
                        </v-sheet>
                    </div>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('event_label_photos') }}</div>
                        <div class="horizontal-input">
                            <v-file-input
                                v-model="event.photoUps"
                                required
                                outlined
                                prepend-icon=""
                                prepend-inner-icon="mdi-paperclip"
                                dense
                                multiple
                                :placeholder="t('file_manager_label_browse_files')"
                                :error-messages="photoForm.errors.get('photos.0') || photoForm.errors.get('photos')"
                                @click:clear="event.photoUps = undefined; photoForm.errors.clear('photos.0'); photoForm.errors.clear('photos')"
                            ></v-file-input>
                        </div>
                    </div>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('event_label_registration_url') }}</div>
                        <div class="horizontal-input">
                            <v-text-field
                                v-model="event.registrationUrl"
                                type="text"
                                required
                                outlined
                                dense
                                :error-messages="form.errors.get('registration_url')"
                            ></v-text-field>
                        </div>
                    </div>
                    <v-switch
                        class="ma-0"
                        v-model="event.hideUsers"
                        :label="t('event_label_hide_users')"
                    ></v-switch>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('event_label_attendees') }}</div>
                        <div class="horizontal-input">
                            <user-search-modal
                                v-model="event"
                            >
                                <template #default="{ on, attrs }">
                                    <v-btn
                                        class="exs mt-1"
                                        color="primary"
                                        small
                                        v-on="on"
                                        v-bind="attrs"
                                    >{{ t('general_action_add_new') }}</v-btn>
                                </template>
                            </user-search-modal>
                        </div>
                    </div>
                    <template v-if="event.participants.length">
                        <v-btn
                            @click="event.participants = []"
                            v-ripple="false"
                            plain
                            color="orange darken-4"
                            style="font-size: 12px; margin: 0 0 0 314px"
                        >{{ t('general_action_clear_all') }}</v-btn>
                        <v-card class="app-card app-card-bordered">
                            <v-card-text>
                                <v-list>
                                    <v-list-item
                                        v-for="participant in event.participants"
                                        :key="`participant_${participant.id}`"
                                    >
                                        {{ participant.fullName }} | {{ participant.id }}
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            @click="detach(participant)"
                                            v-ripple="false"
                                            plain
                                            color="orange darken-4"
                                            style="font-size: 12px"
                                        >{{ t('general_action_remove') }}</v-btn>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </template>
                </v-form>
            </v-card-text>

            <v-card-actions class="px-4 pt-8">
                <v-btn
                    :to="{ name: 'events' }"
                    class="mr-4"
                    color="primary"
                >{{ t('general_action_cancel') }}</v-btn>
                <v-btn
                    :loading="form.busy"
                    :disabled="form.busy"
                    color="orange"
                    @click.prevent="submit"
                >{{ event.exists() ? t('general_action_save') : t('event_title_create') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-layout>
</div>
