
<v-flex>
    <v-progress-linear
        v-if="overlay && ! (!! user)"
        absolute
        indeterminate
    ></v-progress-linear>

    <card-collection
        :class="{ 'px-0-nested': !! user }"
        :meta="meta"
        :busy="overlay"
        @updated="updated"
    >
        <template #filters>
            <v-toolbar
                v-if="! (!! user) && permitted('admin_group_management')"
                elevation="0"
                color="transparent"
                class="mt-7 mb-n3"
                height="56"
            >
                <v-btn
                    :to="{ name: 'groups.create' }"
                    class="sm"
                    color="orange"
                >{{ t('general_action_create_new') }}
                </v-btn>
            </v-toolbar>
            <v-toolbar
                elevation="0"
                color="transparent"
                :class="{ 'mt-7 mb-1': ! (!! user) }"
                height="56"
            >
                <v-text-field
                    v-model="queries.search"
                    @click:clear="clear"
                    class="input-search md mr-2"
                    clearable
                    outlined
                    dense
                    hide-details
                    prepend-inner-icon="mdi-magnify"
                    :label="t('general_label_search')"
                ></v-text-field>

                <span class="sub-label px-5">{{ t('general_label_created') }}</span>

                <date-picker-modal
                    :value="queries.created_from"
                    @updated="(v) => queries.created_from = v"
                >
                    <template #default="{ on, attrs }">
                        <div
                            v-on="on"
                            v-bind="attrs"
                        >
                            <v-text-field
                                v-model="queries.created_from"
                                class="xs input-color-inherit"
                                outlined
                                dense
                                hide-details
                                readonly
                                :label="t('general_label_from')"
                            ></v-text-field>
                        </div>
                    </template>
                </date-picker-modal>

                <span class="sub-label px-2">-</span>

                <date-picker-modal
                    :value="queries.created_to"
                    @updated="(v) => queries.created_to = v"
                >
                    <template #default="{ on, attrs }">
                        <div
                            v-on="on"
                            v-bind="attrs"
                        >
                            <v-text-field
                                v-model="queries.created_to"
                                class="xs input-color-inherit"
                                outlined
                                dense
                                hide-details
                                readonly
                                :label="t('general_label_to')"
                            ></v-text-field>
                        </div>
                    </template>
                </date-picker-modal>

                <v-select
                    v-model="queries.creators"
                    :items="communityExtraCreators()"
                    class="md ml-5"
                    outlined
                    dense
                    append-icon="mdi-chevron-down"
                    hide-details
                    :label="t('general_label_created_by')"
                ></v-select>

                <v-btn
                    @click="search"
                    class="sm mx-5"
                    color="primary"
                >{{ t('general_action_search') }}
                </v-btn>

                <v-btn
                    v-ripple="false"
                    plain
                    color="orange darken-4"
                    @click="reset"
                >{{ t('general_action_reset_filters') }}
                </v-btn>

            </v-toolbar>

            <v-toolbar
                v-if="! (!! user)"
                elevation="0"
                color="transparent"
                class="mb-0"
                height="56"
            >
                <v-checkbox
                    v-model="queries.only_suspended"
                    :label="t('general_label_show_suspended')"
                    hide-details
                    class="ma-0 customized-deep-primary-color"
                />
            </v-toolbar>

        </template>

        <template #data>
            <thead>
            <tr>
                <th class="touched">
                    <sort-field
                        v-model="queries.sort"
                        field="id"
                        @touched="onSort"
                    >{{ t('group_label_id') }}</sort-field>
                </th>
                <th class="touched">
                    <sort-field
                        v-model="queries.sort"
                        field="name"
                        @touched="onSort"
                    >{{ t('group_label_name') }}</sort-field>
                </th>
                <th>{{ t('general_label_creation_date') }}</th>
                <th>{{ t('general_label_created_by') }}</th>
                <th>{{ t('group_label_members') }}</th>
                <th class="text-center">{{ t('general_label_action') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="collection.data.length === 0">
                <td class="text-center" colspan="6">{{ t('general_text_no_data') }}</td>
            </tr>
            <template v-else v-for="group in collection.data">
                <tr>
                    <td>
                        <a
                            :href="`/groups/${group.id}/view`"
                            @click.prevent="view(group)"
                            :class="{ 'orange--text': ! group.enabled }"
                        >{{ group.id }}</a>
                    </td>
                    <td>
                        <a
                            :href="`/groups/${group.id}/view`"
                            @click.prevent="view(group)"
                        >{{ group.name }}</a>
                    </td>
                    <td>{{ group.createdAt ? group.createdAt.format('DD/MM/YYYY HH:mm') : '-' }}</td>
                    <td>
                        <template v-if="! group.userId">{{ communityExtraCreatorTitle(group.creator) }}</template>
                        <a
                            v-else
                            @click="go({ name: 'trainees.show', params: { id: group.userId }, query: { tab: '5' } })"
                        >#{{ group.user.fullName }}</a>
                    </td>
                    <td>{{ group.membersCount }}</td>
                    <td
                        class="text-center"
                    >
                        <v-menu
                            v-if="! (!! user)"
                            offset-y
                            left
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item
                                    @click.prevent="view(group)"
                                >
                                    <v-list-item-title
                                        class="customized-deep-primary--text"
                                    >{{ t('general_action_details') }}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    v-if="permitted('admin_group_management')"
                                    @click.prevent="edit(group)"
                                >
                                    <v-list-item-title
                                        class="customized-deep-primary--text"
                                    >{{ t('general_action_edit') }}</v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    v-if="permitted('admin_group_management')"
                                    @click.prevent="suspend(group)"
                                >
                                    <v-list-item-title
                                        v-if="group.enabled"
                                        class="customized-deep-primary--text"
                                    >{{ t('general_action_suspend') }}
                                    </v-list-item-title>
                                    <v-list-item-title
                                        v-else
                                        class="orange--text"
                                    >{{ t('general_action_unsuspend') }}
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-btn
                            v-else
                            icon
                            :to="`/groups/${group.id}/view`"
                        >
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </template>
            </tbody>
        </template>
    </card-collection>
</v-flex>
