
<v-dialog
    v-model="touched"
>
    <template #activator="{ on, attrs }">
        <slot v-bind="{ on, attrs }"></slot>
    </template>

    <v-card>
        <v-card-title class="customized-primary white--text">
            {{ value.param_key }}
        </v-card-title>

        <v-card-text class="pb-0">
            <v-form class="mt-6">
                <v-textarea
                    v-model="value.param_value"
                    type="text"
                    :label="t('param_label_value')"
                    required
                    outlined
                    :error-messages="form.errors.get('param_value')"
                ></v-textarea>
            </v-form>
        </v-card-text>

        <v-card-actions>
            <v-btn
                color="primary"
                @click="submit"
                width="45%"
                max-width="360px"
                dark
            >{{ t('general_action_update') }}</v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="orange"
                @click="touched = false"
                width="45%"
                max-width="360px"
                dark
            >{{ t('general_action_cancel') }}</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
