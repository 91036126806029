<template>
    <div>
        <app-bar
            :title="workout.name"
        />

        <v-layout>
            <v-progress-linear
                v-if="overlay"
                absolute
                indeterminate
            ></v-progress-linear>

            <div class="pt-8 pb-12 px-8">
                <div v-if="permitted('admin_workout_management')" class="pb-10">
                    <v-btn
                        v-if="workout.creator !== 'system'"
                        @click="edit"
                        class="sm mr-5"
                        color="primary"
                    >{{ t('general_action_edit') }}</v-btn>
                    <v-btn
                        v-if="! workout.archived"
                        class="sm mr-5"
                        color="primary"
                        outlined
                        @click.prevent="suspend(workout)"
                    >
                        {{ workout.enabled ? t('general_action_suspend') : t('general_action_unsuspend') }}
                    </v-btn>
                    <v-btn
                        class="sm mr-5"
                        color="customized-orange"
                        outlined
                        @click.prevent="archived(workout)"
                    >
                        {{ workout.archived ? t('general_action_unarchived') : t('general_action_archived') }}
                    </v-btn>
                    <confirm-modal
                        :title="t('general_title_confirm_action')"
                        @agree="del(workout)"
                    >
                        <template #default="{ on, attrs }">
                            <v-btn
                                class="sm"
                                color="orange"
                                outlined
                                v-on="on"
                                v-bind="attrs"
                            >{{ t('general_action_delete') }}</v-btn>
                        </template>
                    </confirm-modal>
                </div>
                <div class="float-left mr-10" style="max-width: 394px">
                    <img
                        class=""
                        v-if="workout.photoUrl"
                        :src="workout.photoUrl"
                        width="394"
                    />
                    <div class="form-subtitle">{{ t('workout_label_description') }}:</div>
                    <div class="form-subtitle-description">{{ workout.description }}</div>
                    <div class="form-subtitle">{{ t('workout_label_coach_advice') }}:</div>
                    <div class="form-subtitle-description">{{ workout.coachAdvice }}</div>
                    <card-unit :items="items()">
                        <template #title>{{ t('workout_label_id') }}: {{ workout.id }}</template>
                    </card-unit>
                </div>
                <div class="float-left mr-10">
                    <v-tabs
                        v-if="workout.sets.length"
                        v-model="currentTab"
                        height="52"
                    >
                        <div class="tabs-line"></div>
                        <v-tabs-slider></v-tabs-slider>

                        <template v-for="(set, index) in workout.sets">
                            <v-tab :class="{ 'pl-0': index === 0 }">{{ t('workout_set_prefix') }} {{ index + 1 }}</v-tab>
                            <v-tab-item class="pt-7">
                                <card-unit
                                    untitled
                                    :items="[
                                    { text: t('workout_set_label_phase'), value: phaseTitle(set.phase) },
                                    { text: t('workout_set_label_interval'), value: intervalTitle(set.interval) },
                                    { text: t('workout_set_label_repetition_numbers'), value: set.repetitionNumbers },
                                    { text: t('workout_set_label_distance'), value: set.distance + ' m' },
                                    { text: t('workout_set_label_stroke_type'), value: set.stroke.name },
                                    { text: t('workout_set_label_duration'), value: set.duration },
                                    { text: t('workout_set_label_intensity'), value: intensityTitle(set.intensity) },
                                    { text: t('workout_set_label_rest_time'), value: set.restTime },
                                    { text: t('workout_set_label_calories_burned'), value: set.caloriesBurned * set.repetitionNumbers },
                                    { text: t('workout_set_label_steps'), value: set.steps * set.repetitionNumbers },
                                    { text: t('workout_set_label_accessories'), value: accessoriesStrTitle(set.accessories) },
                                    { text: t('workout_set_label_description'), value: set.description },
                                ]">
                                </card-unit>
                            </v-tab-item>
                        </template>
                    </v-tabs>
                </div>
            </div>

        </v-layout>
    </div>
</template>

<script lang="ts">
    import { computed, defineComponent, onMounted, reactive, toRefs } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useStore } from '@/store'
    import { useRouter } from '@/composition-api'
    import { useEnums } from '@/core/defaults'
    import { Workout } from '@/core/models/workout'
    import moment from 'moment/moment'
    import CardUnit from '@/components/CardUnit.vue'
    import ConfirmModal from "@/components/ConfirmModal.vue";

    export default defineComponent({
        components: {
            ConfirmModal,
            CardUnit
        },

        /**
         * Component constructor.
         *
         * @param props
         */
        setup(props) {
            const store = useStore()
            const router = useRouter()
            const enums = useEnums()
            const i18n = useI18n()
            const id = router.currentRoute.params['id']

            const state = reactive({
                overlay: true,
                currentTab: <number | null>null,
                photo: <File | undefined>undefined,
            })

            const capture = () => {
                store.dispatch('workout/get', { id: id, payload: { 'relations': 'user,stroke,strokes,sets,sets.stroke,programs,executions' } }).then(() => {
                    state.overlay = false
                })
            }

            const workoutModel = computed(() => <Workout>store.getters['workout/get'])

            const suspend = (workoutModel: Workout) => {
                state.overlay = true
                store.dispatch('workout/update', { id: workoutModel.id, payload: { enabled: ! workoutModel.enabled, only: 'enabled' } }).then(() => {
                    capture()
                })
            }

            const archived = (workoutModel: Workout) => {
                state.overlay = true
                store.dispatch('workout/update', { id: workoutModel.id, payload: { archived: ! workoutModel.archived, only: 'archived' } }).then(() => {
                    capture()
                })
            }

            const edit = () => router.push({ name: 'workouts.show', params: { id: <any>workoutModel.value.id } })

            const del = (workoutModel: Workout) => {
                state.overlay = true
                store.dispatch('workout/delete', { id: workoutModel.id, force: true }).then(() => {
                    router.push({ name: 'workouts' })
                })
            }

            const items = () => {
                let items = <any>[
                    { text: i18n.t('workout_label_created_by'), value: enums.creatorTitle(workoutModel.value.creator) },
                ]
                if (workoutModel.value.userId) {
                    items = items.concat([{
                        text: i18n.t('general_label_user'),
                        value: workoutModel.value.user.fullName,
                        route: { name: 'trainees.show', params: <any>{ id: workoutModel.value.userId }, query: { tab: '3' } }
                    }])
                }
                items = items.concat([
                    { text: i18n.t('workout_label_executions_count'), value: workoutModel.value.executions.length },
                    { text: i18n.t('workout_label_sport_type'), value: enums.sportTypeTitle(workoutModel.value.sportType) },
                    { text: i18n.t('workout_label_workout_types'), value: enums.workoutTypesStrTitle(workoutModel.value.workoutTypes) },
                    { text: i18n.t('workout_label_swimming_location'), value: enums.locationTitle(workoutModel.value.swimmingLocation) },
                    { text: i18n.t('workout_label_pool_length'), value: enums.poolLengthsStrTitle(workoutModel.value.poolLengths) || '-' },
                    { text: i18n.t('workout_label_pool_25'), value: workoutModel.value.pool25 ? i18n.t('general_text_yes') : i18n.t('general_text_no') },
                    { text: i18n.t('workout_label_stroke'), value: workoutModel.value.stroke.name || '-' },
                    { text: i18n.t('workout_label_strokes'), value: workoutModel.value.strokesStrTitle || '-' },
                    { text: i18n.t('workout_label_goals'), value: workoutModel.value.goalsStrTitle },
                    { text: i18n.t('workout_label_level'), value: enums.levelTitle(workoutModel.value.level) },
                    { text: i18n.t('workout_label_total_time'), value: workoutModel.value.workoutTotalTime },
                    { text: i18n.t('workout_label_swimming_time'), value: workoutModel.value.workoutDuration },
                    { text: i18n.t('workout_label_rest_time'), value: workoutModel.value.workoutRestTime },
                    { text: i18n.t('workout_label_distance'), value: workoutModel.value.workoutDistance + ' m' },
                    { text: i18n.t('workout_label_sets_number'), value: workoutModel.value.sets.length.toString() },
                    { text: i18n.t('workout_label_calories_burned'), value: workoutModel.value.workoutCaloriesBurned },
                    { text: i18n.t('workout_label_steps'), value: workoutModel.value.workoutSteps },
                ])
                items = items.concat([{
                    text: i18n.t('workout_label_programs_used'),
                    value:
                        workoutModel.value.programs.length ?
                            workoutModel.value.programs.map(item => { return { value: item.name, route: { name: 'programs.view', params: { id: item.id } } } }) :
                            i18n.t('general_text_no'),
                }])
                return items
            }

            onMounted(() => {
                capture()
            })
            
            return {
                capture,
                items,
                workout: workoutModel.value,
                moment,
                edit,
                del,
                suspend,
                archived,
                ...useI18n(),
                ...useEnums(),
                ...toRefs(state)
            }
        }
    })
</script>
