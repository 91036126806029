<template>
    <v-dialog
        v-model="touched"
    >
        <template #activator="{ on, attrs }">
            <slot v-bind="{ on, attrs }"></slot>
        </template>

        <v-card>
            <v-card-title class="customized-primary white--text">
                {{ value.param_key }}
            </v-card-title>

            <v-card-text class="pb-0">
                <v-form class="mt-6">
                    <v-textarea
                        v-model="value.param_value"
                        type="text"
                        :label="t('param_label_value')"
                        required
                        outlined
                        :error-messages="form.errors.get('param_value')"
                    ></v-textarea>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-btn
                    color="primary"
                    @click="submit"
                    width="45%"
                    max-width="360px"
                    dark
                >{{ t('general_action_update') }}</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="orange"
                    @click="touched = false"
                    width="45%"
                    max-width="360px"
                    dark
                >{{ t('general_action_cancel') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
    import { defineComponent, reactive, toRefs } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useStore } from '@/store'
    import { useForm } from '@/core/form'
    import { Param } from '@/core/models/param'

    export default defineComponent({
        name: 'ParamModal',

        /**
         * Component props.
         */
        props: {
            value: {
                type: Param,
                required: true
            }
        },

        /**
         * Component constructor.
         *
         * @param props
         * @param context
         */
        setup(props, context) {
            const store = useStore()

            const state = reactive({
               touched: false
            })

            const i18n = useI18n()

            const form = reactive(useForm())

            const submit = () => {
                form
                    .usingSubmit(
                        store.dispatch(
                            'param/update',
                            { id: props.value.id, payload: props.value.payload() }
                        )
                    )
                    .then(() => {
                        state.touched = false
                        store.dispatch('notify', { color: 'success', message: i18n.t('general_notice_data_updated') })
                    })
            }

            return {
                form,
                submit,
                ...useI18n(),
                ...toRefs(state)
            }
        }
    })
</script>
