import store from '@/store'
import { Language } from '@/core/models/language'

interface IItem {
    text: string
    value: string | null
}

const getTitle = (items: IItem[], value: string): string => {
    for (let i = 0; i < items.length; i++) {
        if (value === items[i].value) {
            return items[i].text
        }
    }
    return '-'
}

export const useDefaults = () => {

    const defaults = () => {
        return store.getters['defaults']
    }

    const languages = () => {
        return store.getters['languages']
    }

    const enums = () => {
        return store.getters['enums']
    }

    return {
        defaults,
        languages,
        enums,
    }
}

export const useLocales = () => {

    const locales = (fictitious: boolean = false): IItem[] => {
        let items = <any>[]
        useDefaults().languages().forEach((item: Language) => {
            if (! fictitious && item.languageCode === 'xx') {
                return;
            }
            items.push({
                text: item.name,
                value: item.languageCode,
            })
        })
        return items
    }

    const localeTitle = (value: string): string => {
        return getTitle(locales(), value)
    }

    return {
        locales,
        localeTitle,
    }
}

export const useLanguages = () => {

    const languages = (): IItem[] => {
        let items = <any>[]
        useDefaults().languages().forEach((item: Language) => {
            items.push({
                text: item.name,
                value: item.id,
            })
        })
        return items
    }

    const languageTitle = (value: string): string => {
        return getTitle(languages(), value)
    }

    return {
        languages,
        languageTitle
    }
}

export const useEnums = () => {

    const defaults = useDefaults()

    const getItems = (group: string): IItem[] => {
        let items = <any>[]
        for (const [key, value] of Object.entries(defaults.enums()[group])) {
            items.push({
                text: value,
                value: key,
            })
        }
        return items
    }

    const genders = () => {
        return getItems('genders')
    }

    const genderTitle = (value: string): string => {
        return getTitle(getItems('genders'), value)
    }

    const weightUnits = () => {
        return getItems('weight_units')
    }

    const weightUnitTitle = (value: string): string => {
        return getTitle(getItems('weight_units'), value)
    }

    const heightUnits = () => {
        return getItems('height_units')
    }

    const heightUnitTitle = (value: string): string => {
        return getTitle(getItems('height_units'), value)
    }

    const levels = () => {
        return getItems('levels')
    }

    const levelTitle = (value: string): string => {
        return getTitle(getItems('levels'), value)
    }

    const levelColor = (value: string): string => {
        if (value === 'beginner') {
            return 'customized-green'
        } else if (value === 'intermediate') {
            return 'customized-orange'
        } else if (value === 'advanced') {
            return 'customized-primary'
        } else if (value === 'competition') {
            return 'customized-red'
        } else {
            return ''
        }
    }

    const locations = () => {
        return getItems('swimming_locations')
    }

    const locationTitle = (value: string): string => {
        return getTitle(getItems('swimming_locations'), value)
    }

    const locationsStrTitle = (value: any[] = [], separator: string = ' / '): string => {
        if (! value) {
            return '-'
        }
        let titles = <any>[]
        value.map(item => titles.push(locationTitle(item)))
        return titles.join(separator)
    }

    const intensities = () => {
        return getItems('intensities')
    }

    const intensityTitle = (value: string): string => {
        return getTitle(getItems('intensities'), value)
    }

    const workoutTypes = () => {
        return getItems('workout_types')
    }

    const workoutTypeTitle = (value: string): string => {
        return getTitle(getItems('workout_types'), value)
    }

    const workoutTypesStrTitle = (value: any[] = [], separator: string = ' / '): string => {
        if (! value) {
            return '-'
        }
        let titles = <any>[]
        value.map(item => titles.push(workoutTypeTitle(item)))
        return titles.join(separator)
    }

    const distanceValues = () => {
        return getItems('distance_values')
    }

    const distanceValueTitle = (value: string): string => {
        return getTitle(getItems('distance_values'), value)
    }

    const durationValues = () => {
        return getItems('duration_values')
    }

    const durationValueTitle = (value: string): string => {
        return getTitle(getItems('duration_values'), value)
    }

    const sportTypes = () => {
        return getItems('sport_types')
    }

    const sportTypeTitle = (value: string): string => {
        return getTitle(getItems('sport_types'), value)
    }

    const phases = () => {
        return getItems('phases')
    }

    const phaseTitle = (value: string): string => {
        return getTitle(getItems('phases'), value)
    }

    const creators = () => {
        return getItems('creators')
    }

    const creatorTitle = (value: string): string => {
        return getTitle(getItems('creators'), value)
    }

    const communityCreators = () => {
        return getItems('community_creators')
    }

    const communityCreatorTitle = (value: string): string => {
        return getTitle(getItems('community_creators'), value)
    }

    const communityExtraCreators = () => {
        return getItems('community_extra_creators')
    }

    const communityExtraCreatorTitle = (value: string): string => {
        return getTitle(getItems('community_extra_creators'), value)
    }

    const calendarDurationValues = () => {
        return getItems('calendar_duration_values')
    }

    const calendarDurationValueTitle = (value: string): string => {
        return getTitle(getItems('calendar_duration_values'), value)
    }

    const calendarDurationUnits = () => {
        return getItems('calendar_duration_units')
    }

    const calendarDurationUnitTitle = (value: string): string => {
        return getTitle(getItems('calendar_duration_units'), value)
    }

    const programStatuses = () => {
        return getItems('program_statuses')
    }

    const programStatusTitle = (value: string): string => {
        return getTitle(getItems('program_statuses'), value)
    }

    const userStatuses = () => {
        return getItems('user_statuses')
    }

    const userStatusTitle = (value: string): string => {
        return getTitle(getItems('user_statuses'), value)
    }

    const providers = () => {
        return getItems('providers')
    }

    const providerTitle = (value: string): string => {
        return getTitle(getItems('providers'), value)
    }

    const accessories = () => {
        return getItems('accessories')
    }

    const accessoryTitle = (value: string): string => {
        return getTitle(getItems('accessories'), value)
    }

    const accessoriesStrTitle = (value: any[] = [], separator: string = ' / '): string => {
        if (! value) {
            return '-'
        }
        let titles = <any>[]
        value.map(item => titles.push(accessoryTitle(item)))
        return titles.join(separator)
    }

    const oss = () => {
        return getItems('oss')
    }

    const osTitle = (value: string): string => {
        return getTitle(getItems('oss'), value)
    }

    const eventTypes = () => {
        return getItems('event_types')
    }

    const eventTypeTitle = (value: string): string => {
        return getTitle(getItems('event_types'), value)
    }

    const eventStatuses = () => {
        return getItems('event_statuses')
    }

    const eventStatusTitle = (value: string): string => {
        return getTitle(getItems('event_statuses'), value)
    }

    const repeatTypes = () => {
        return getItems('repeat_types')
    }

    const repeatTypeTitle = (value: string): string => {
        return getTitle(getItems('repeat_types'), value)
    }

    const targetTypes = () => {
        return getItems('target_types')
    }

    const targetTypeTitle = (value: string): string => {
        return getTitle(getItems('target_types'), value)
    }

    const groupTypes = () => {
        return getItems('group_types')
    }

    const groupTypeTitle = (value: string): string => {
        return getTitle(getItems('group_types'), value)
    }

    const permissions = () => {
        return getItems('permissions')
    }

    const permissionTitle = (value: string): string => {
        return getTitle(getItems('permissions'), value)
    }

    const feedbackFeelings = () => {
        return getItems('feedback_feelings')
    }

    const feedbackFeelingTitle = (value: string): string => {
        return getTitle(getItems('feedback_feelings'), value)
    }

    const feedbackLevels = () => {
        return getItems('feedback_levels')
    }

    const feedbackLevelTitle = (value: string): string => {
        return getTitle(getItems('feedback_levels'), value)
    }

    const feedbackFitInTime = () => {
        return getItems('feedback_fit_in_time')
    }

    const feedbackFitInTimeTitle = (value: string): string => {
        return getTitle(getItems('feedback_fit_in_time'), value)
    }

    const feedbackDescriptions = () => {
        return getItems('feedback_descriptions')
    }

    const feedbackDescriptionTitle = (value: string): string => {
        return getTitle(getItems('feedback_descriptions'), value)
    }

    const intervals = () => {
        return getItems('intervals')
    }

    const intervalTitle = (value: string): string => {
        return getTitle(getItems('intervals'), value)
    }

    const notificationStatuses = () => {
        return getItems('notification_statuses')
    }

    const notificationStatusTitle = (value: string): string => {
        return getTitle(getItems('notification_statuses'), value)
    }

    const complaintStatuses = () => {
        return getItems('complaint_statuses')
    }

    const complaintStatusTitle = (value: string): string => {
        return getTitle(getItems('complaint_statuses'), value)
    }

    const ratingValues = () => {
        return getItems('rating_values')
    }

    const ratingValueTitle = (value: string): string => {
        return getTitle(getItems('rating_values'), value)
    }

    const activityProviders = (external: boolean = false) => {
        let items = getItems('activity_providers');
        if (external) {
            items = items.filter((i) => i.value !== 'app')
        }
        return items
    }

    const activityProviderTitle = (value: string): string => {
        return getTitle(getItems('activity_providers'), value)
    }

    const activityProvidersStrTitle = (value: any[] = [], separator: string = ' / '): string => {
        if (! value) {
            return '-'
        }
        let titles = <any>[]
        value.map(item => titles.push(activityProviderTitle(item)))
        return titles.join(separator)
    }

    const poolLengths = () => {
        return getItems('pool_lengths')
    }

    const poolLengthTitle = (value: string): string => {
        return getTitle(getItems('pool_lengths'), value)
    }

    const poolLengthsStrTitle = (value: any[] = [], separator: string = ' / '): string => {
        if (! value) {
            return '-'
        }
        let titles = <any>[]
        value.map(item => titles.push(poolLengthTitle(item)))
        return titles.join(separator)
    }

    const badgeTriggers = () => {
        return getItems('badge_triggers')
    }

    const badgeTriggerTitle = (value: string): string => {
        return getTitle(getItems('badge_triggers'), value)
    }

    const badgeStats = () => {
        return getItems('badge_stats')
    }

    const badgeStatTitle = (value: string): string => {
        return getTitle(getItems('badge_stats'), value)
    }

    const distanceUnits = () => {
        return getItems('distance_units')
    }

    const distanceUnitTitle = (value: string): string => {
        return getTitle(getItems('distance_units'), value)
    }

    const ageValues = () => {
        return getItems('age_values')
    }

    const ageValueTitle = (value: string): string => {
        return getTitle(getItems('age_values'), value)
    }

    const associates = () => {
        return getItems('associates')
    }

    const associateTitle = (value: string): string => {
        return getTitle(getItems('associates'), value)
    }

    return {
        genders,
        genderTitle,
        weightUnits,
        weightUnitTitle,
        heightUnits,
        heightUnitTitle,
        levels,
        levelTitle,
        levelColor,
        locations,
        locationTitle,
        locationsStrTitle,
        intensities,
        intensityTitle,
        workoutTypes,
        workoutTypeTitle,
        workoutTypesStrTitle,
        distanceValues,
        distanceValueTitle,
        durationValues,
        durationValueTitle,
        sportTypes,
        sportTypeTitle,
        phases,
        phaseTitle,
        creators,
        communityCreators,
        communityCreatorTitle,
        communityExtraCreators,
        communityExtraCreatorTitle,
        creatorTitle,
        calendarDurationValues,
        calendarDurationValueTitle,
        calendarDurationUnits,
        calendarDurationUnitTitle,
        programStatuses,
        programStatusTitle,
        userStatuses,
        userStatusTitle,
        providers,
        providerTitle,
        accessories,
        accessoryTitle,
        accessoriesStrTitle,
        oss,
        osTitle,
        eventTypes,
        eventTypeTitle,
        eventStatuses,
        eventStatusTitle,
        repeatTypes,
        repeatTypeTitle,
        targetTypes,
        targetTypeTitle,
        groupTypes,
        groupTypeTitle,
        permissions,
        permissionTitle,
        feedbackFeelings,
        feedbackFeelingTitle,
        feedbackLevels,
        feedbackLevelTitle,
        feedbackFitInTime,
        feedbackFitInTimeTitle,
        feedbackDescriptions,
        feedbackDescriptionTitle,
        intervals,
        intervalTitle,
        notificationStatuses,
        notificationStatusTitle,
        complaintStatuses,
        complaintStatusTitle,
        ratingValues,
        ratingValueTitle,
        activityProviders,
        activityProviderTitle,
        activityProvidersStrTitle,
        poolLengths,
        poolLengthTitle,
        poolLengthsStrTitle,
        badgeTriggers,
        badgeTriggerTitle,
        badgeStats,
        badgeStatTitle,
        distanceUnits,
        distanceUnitTitle,
        ageValues,
        ageValueTitle,
        associates,
        associateTitle,
    }
}
