
<div class="page-customized">
    <v-progress-linear
        v-if="overlay"
        absolute
        indeterminate
    ></v-progress-linear>

    <app-bar
        :title="workout.exists() ? t('workout_title_update') : t('workout_title_create')"
        back="/workouts"
    />

    <v-layout class="mt-n8">
        <v-card
            width="100%"
            color="transparent"
            elevation="0"
            class="pb-8"
        >
            <v-card-text class="pb-0">
                <v-form class="mt-6">
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('workout_label_name') }}</div>
                        <div class="horizontal-input">
                            <v-text-field
                                v-model="workout.name"
                                type="text"
                                required
                                outlined
                                dense
                                :error-messages="form.errors.get('name')"
                            ></v-text-field>
                        </div>
                        <div class="horizontal-actions">
                            <edit-translations-modal
                                v-model="workout.name"
                                :items="workout.translations['name']"
                                @touch="(v) => workout.translations['name'] = v"
                            ></edit-translations-modal>
                        </div>
                    </div>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('workout_label_description') }}</div>
                        <div class="horizontal-input">
                            <v-textarea
                                v-model="workout.description"
                                required
                                outlined
                                dense
                                :error-messages="form.errors.get('description')"
                            ></v-textarea>
                        </div>
                        <div class="horizontal-actions">
                            <edit-translations-modal
                                v-model="workout.description"
                                :items="workout.translations['description']"
                                @touch="(v) => workout.translations['description'] = v"
                                text
                            ></edit-translations-modal>
                        </div>
                    </div>
                    <div
                        v-if="workout.creator === 'openswim'"
                        class="form-group-horizontal-cols"
                    >
                        <div class="form-group-horizontal form-group-horizontal-col-1">
                            <div class="horizontal-label">{{ t('workout_label_coach') }}</div>
                            <div class="horizontal-input">
                                <v-select
                                    v-model="workout.userId"
                                    :items="users"
                                    required
                                    outlined
                                    append-icon="mdi-chevron-down"
                                    dense
                                    :error-messages="form.errors.get('user_id')"
                                ></v-select>
                            </div>
                        </div>
                    </div>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('workout_label_coach_advice') }}</div>
                        <div class="horizontal-input">
                            <v-textarea
                                v-model="workout.coachAdvice"
                                required
                                outlined
                                dense
                                :error-messages="form.errors.get('coach_advice')"
                            ></v-textarea>
                        </div>
                        <div class="horizontal-actions">
                            <edit-translations-modal
                                v-model="workout.coachAdvice"
                                :items="workout.translations['coach_advice']"
                                @touch="(v) => workout.translations['coach_advice'] = v"
                                text
                            ></edit-translations-modal>
                        </div>
                    </div>
                    <div class="form-group-horizontal-cols">
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('workout_label_photo') }}</div>
                            <div class="horizontal-input">
                                <v-file-input
                                    v-model="workout.photo"
                                    required
                                    outlined
                                    prepend-icon=""
                                    prepend-inner-icon="mdi-paperclip"
                                    :append-outer-icon="workout.photoUrl ? 'mdi-eye-outline' : ''"
                                    dense
                                    :error-messages="form.errors.get('photo')"
                                    @click:append-outer="viewPhoto()"
                                    @click:clear="workout.photo = undefined"
                                ></v-file-input>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('workout_label_level') }}</div>
                            <div class="horizontal-input">
                                <v-select
                                    v-model="workout.level"
                                    :items="levels()"
                                    required
                                    outlined
                                    append-icon="mdi-chevron-down"
                                    dense
                                    :error-messages="form.errors.get('level')"
                                ></v-select>
                            </div>
                        </div>
                    </div>

                    <div class="form-group-horizontal-cols">
                        <div class="form-group-horizontal form-group-horizontal-col-1">
                            <div class="horizontal-label">{{ t('workout_label_sport') }}</div>
                            <div class="horizontal-input">
                                <v-select
                                    v-model="workout.sportType"
                                    :items="sportTypes()"
                                    required
                                    outlined
                                    append-icon="mdi-chevron-down"
                                    dense
                                    disabled
                                    :error-messages="form.errors.get('sport_type')"
                                ></v-select>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('workout_label_dominant_stroke') }}</div>
                            <div class="horizontal-input">
                                <v-select
                                    v-model="workout.strokeId"
                                    :items="primaryStrokes"
                                    item-text="name"
                                    item-value="id"
                                    required
                                    outlined
                                    append-icon="mdi-chevron-down"
                                    dense
                                    :error-messages="form.errors.get('stroke_id')"
                                ></v-select>
                            </div>
                        </div>
                    </div>
                    <div class="form-group-horizontal-cols">
                        <div class="form-group-horizontal form-group-horizontal-col-1">
                            <div class="horizontal-label">{{ t('workout_label_swimming_location') }}</div>
                            <div class="horizontal-input">
                                <v-select
                                    v-model="workout.swimmingLocation"
                                    :items="locations()"
                                    required
                                    outlined
                                    append-icon="mdi-chevron-down"
                                    dense
                                    :error-messages="form.errors.get('swimming_location')"
                                ></v-select>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('workout_label_strokes') }}</div>
                            <div class="horizontal-input">
                                <v-select
                                    v-model="workout.strokeIds"
                                    :items="primaryStrokes"
                                    item-text="name"
                                    item-value="id"
                                    required
                                    outlined
                                    append-icon="mdi-chevron-down"
                                    dense
                                    multiple
                                    :error-messages="form.errors.get('strokes')"
                                ></v-select>
                            </div>
                        </div>
                    </div>
                    <div class="form-group-horizontal-cols">
                        <div class="form-group-horizontal form-group-horizontal-col-1">
                            <div class="horizontal-label">{{ t('workout_label_workout_types') }}</div>
                            <div class="horizontal-input">
                                <v-select
                                    v-model="workout.workoutTypes"
                                    :items="workoutTypes()"
                                    required
                                    outlined
                                    append-icon="mdi-chevron-down"
                                    dense
                                    multiple
                                    :error-messages="form.errors.get('workout_types')"
                                ></v-select>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('workout_label_goals') }}</div>
                            <div class="horizontal-input">
                                <v-select
                                    v-model="workout.goalIds"
                                    :items="goals.data"
                                    item-text="name"
                                    item-value="id"
                                    required
                                    outlined
                                    append-icon="mdi-chevron-down"
                                    dense
                                    multiple
                                    :error-messages="form.errors.get('workout_goals')"
                                ></v-select>
                            </div>
                        </div>
                    </div>
                    <div class="form-group-horizontal-cols">
                        <div class="form-group-horizontal form-group-horizontal-col-1">
                            <div class="horizontal-label">{{ t('workout_label_distance') }}</div>
                            <div class="horizontal-input">
                                <v-text-field
                                    :value="workout.distance"
                                    v-maska="'######'"
                                    type="number"
                                    step="1"
                                    required
                                    outlined
                                    disabled
                                    dense
                                ></v-text-field>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('workout_label_swimming_time') }}</div>
                            <div class="horizontal-input">
                                <v-text-field
                                    :value="workout.duration"
                                    required
                                    outlined
                                    disabled
                                    dense
                                ></v-text-field>
                            </div>
                        </div>
                    </div>
                    <div class="form-group-horizontal-cols">
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('workout_label_duration') }}</div>
                            <div class="horizontal-input">
                                <v-text-field
                                    :value="workout.totalTime"
                                    required
                                    outlined
                                    disabled
                                    dense
                                ></v-text-field>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('workout_label_pool_25') }}</div>
                            <div class="horizontal-input">
                                <v-switch
                                    class="ma-0"
                                    v-model="workout.pool25"
                                ></v-switch>
                            </div>
                        </div>
                    </div>
                    <div class="form-group-horizontal-cols">
                        <div class="form-group-horizontal form-group-horizontal-col-1">
                            <div class="horizontal-label">{{ t('workout_label_sets_number') }}</div>
                            <div class="horizontal-input">
                                <v-text-field
                                    :value="workout.sets.length"
                                    v-maska="'##'"
                                    type="number"
                                    step="1"
                                    required
                                    outlined
                                    disabled
                                    dense
                                    :error-messages="form.errors.get('sets')"
                                ></v-text-field>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label"></div>
                            <div class="horizontal-input pt-2">
                                <v-btn
                                    @click="addSet"
                                    class="exs"
                                    color="primary"
                                    small
                                >{{ t('workout_set_button_add_new') }}</v-btn>
                            </div>
                        </div>
                    </div>

                    <v-tabs
                        v-if="workout.sets.length"
                        v-model="currentSetTab"
                        height="52"
                    >
                        <div class="tabs-line"></div>
                        <v-tabs-slider></v-tabs-slider>

                        <v-tab
                            :key="`set_name_${index}`"
                            v-for="(set, index) in workout.sets"
                            :class="{ 'pl-0': index === 0, 'red--text': form.errors.consists(`sets.${index}`) }"
                        >{{ t('workout_set_prefix') }} {{ index + 1 }}</v-tab>

                        <v-tabs-items v-model="currentSetTab">
                            <v-tab-item
                                :key="`set_card_${index}`"
                                v-for="(set, index) in workout.sets"
                            >
                                <v-card
                                    width="100%"
                                    color="transparent"
                                    elevation="0"
                                >
                                    <v-card-text class="px-0">
                                        <v-form class="mt-6">
                                            <div class="form-group-horizontal">
                                                <div class="horizontal-label">{{ t('workout_set_label_description') }}</div>
                                                <div class="horizontal-input">
                                                    <v-textarea
                                                        v-model="set.description"
                                                        required
                                                        outlined
                                                        dense
                                                        :error-messages="form.errors.get(`sets.${index}.description`)"
                                                    ></v-textarea>
                                                </div>
                                                <div class="horizontal-actions">
                                                    <edit-translations-modal
                                                        v-model="set.description"
                                                        :items="set.translations['description']"
                                                        @touch="(v) => set.translations['description'] = v"
                                                        text
                                                    ></edit-translations-modal>
                                                </div>
                                            </div>
                                            <div class="form-group-horizontal-cols">
                                                <div class="form-group-horizontal">
                                                    <div class="horizontal-label">{{ t('workout_set_label_phase') }}</div>
                                                    <div class="horizontal-input">
                                                        <v-select
                                                            v-model="set.phase"
                                                            :items="phases()"
                                                            required
                                                            outlined
                                                            append-icon="mdi-chevron-down"
                                                            dense
                                                            :error-messages="form.errors.get(`sets.${index}.phase`)"
                                                        ></v-select>
                                                    </div>
                                                </div>
                                                <div class="form-group-horizontal">
                                                    <div class="horizontal-label">{{ t('workout_set_label_interval') }}</div>
                                                    <div class="horizontal-input">
                                                        <v-btn-toggle
                                                            v-model="set.interval"
                                                            active-class="primary"
                                                            class="my-1"
                                                            dense
                                                            mandatory
                                                            @change="set.captureInterval()"
                                                        >
                                                            <v-btn
                                                                value="classic"
                                                                class="exs mr-3"
                                                                small
                                                            >{{ intervalTitle('classic') }}</v-btn>
                                                            <v-btn
                                                                value="go_off"
                                                                class="exs mr-3"
                                                                small
                                                            >{{ intervalTitle('go_off') }}</v-btn>
                                                            <v-btn
                                                                value="no_time"
                                                                class="exs"
                                                                small
                                                            >{{ intervalTitle('no_time') }}</v-btn>
                                                        </v-btn-toggle>
                                                        <v-input
                                                            type="hidden"
                                                            :error-messages="form.errors.get('target_type')"
                                                        ></v-input>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group-horizontal-cols">
                                                <div class="form-group-horizontal">
                                                    <div class="horizontal-label">{{ t('workout_set_label_stroke') }}</div>
                                                    <div class="horizontal-input">
                                                        <v-select
                                                            v-model="set.strokeId"
                                                            :items="strokes.data"
                                                            item-text="name"
                                                            item-value="id"
                                                            required
                                                            outlined
                                                            append-icon="mdi-chevron-down"
                                                            dense
                                                            :error-messages="form.errors.get(`sets.${index}.stroke_id`)"
                                                        ></v-select>
                                                    </div>
                                                </div>
                                                <div class="form-group-horizontal">
                                                    <div class="horizontal-label">{{ t('workout_set_label_distance') }}</div>
                                                    <div class="horizontal-input">
                                                        <v-text-field
                                                            class="numbar float-left"
                                                            v-model="set.distance"
                                                            v-maska="'######'"
                                                            step="1"
                                                            required
                                                            outlined
                                                            dense
                                                            :error-messages="form.errors.get(`sets.${index}.distance`)"
                                                        ></v-text-field>
                                                        <div class="float-right ml-5 mt-3-1">
                                                            <v-btn
                                                                class="quadro mr-1"
                                                                color="primary"
                                                                small
                                                                @click="set.reduceDistance()"
                                                            ><v-icon>mdi-minus</v-icon></v-btn>
                                                            <v-btn
                                                                class="quadro"
                                                                color="primary"
                                                                small
                                                                @click="set.increaseDistance()"
                                                            ><v-icon>mdi-plus</v-icon></v-btn>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group-horizontal-cols">
                                                <div class="form-group-horizontal">
                                                    <div class="horizontal-label">{{ t('workout_set_label_intensity') }}</div>
                                                    <div class="horizontal-input">
                                                        <v-select
                                                            v-model="set.intensity"
                                                            :items="intensities()"
                                                            required
                                                            outlined
                                                            append-icon="mdi-chevron-down"
                                                            dense
                                                            :error-messages="form.errors.get(`sets.${index}.intensity`)"
                                                        ></v-select>
                                                    </div>
                                                </div>
                                                <div class="form-group-horizontal">
                                                    <div class="horizontal-label">{{ t('workout_set_label_repetition_numbers') }}</div>
                                                    <div class="horizontal-input">
                                                        <v-text-field
                                                            class="numbar float-left"
                                                            v-model="set.repetitionNumbers"
                                                            v-maska="'##'"
                                                            type="number"
                                                            step="1"
                                                            required
                                                            outlined
                                                            dense
                                                            :error-messages="form.errors.get(`sets.${index}.repetition_numbers`)"
                                                        ></v-text-field>
                                                        <div class="float-right ml-5 mt-3-1">
                                                            <v-btn
                                                                class="quadro mr-1"
                                                                color="primary"
                                                                small
                                                                @click="set.reduceRepetition()"
                                                            ><v-icon>mdi-minus</v-icon></v-btn>
                                                            <v-btn
                                                                class="quadro"
                                                                color="primary"
                                                                small
                                                                @click="set.increaseRepetition()"
                                                            ><v-icon>mdi-plus</v-icon></v-btn>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group-horizontal-cols">
                                                <div class="form-group-horizontal">
                                                    <div class="horizontal-label">{{ t('workout_set_label_accessories') }}</div>
                                                    <div class="horizontal-input">
                                                        <v-select
                                                            v-model="set.accessories"
                                                            :items="accessories()"
                                                            required
                                                            outlined
                                                            append-icon="mdi-chevron-down"
                                                            dense
                                                            multiple
                                                            :error-messages="form.errors.get(`sets.${index}.accessories`)"
                                                        ></v-select>
                                                    </div>
                                                </div>
                                                <div
                                                    class="form-group-horizontal"
                                                    v-if="set.interval !== 'no_time'"
                                                >
                                                    <div class="horizontal-label">{{ t('workout_set_label_duration') }}</div>
                                                    <div class="horizontal-input">
                                                        <v-text-field
                                                            class="numbar float-left"
                                                            v-model="set.duration"
                                                            v-maska="'##:##'"
                                                            type="text"
                                                            required
                                                            outlined
                                                            dense
                                                            :error-messages="form.errors.get(`sets.${index}.duration`)"
                                                        ></v-text-field>
                                                        <div class="float-right ml-5 mt-3-1">
                                                            <v-btn
                                                                class="quadro mr-1"
                                                                color="primary"
                                                                small
                                                                @click="set.reduceDuration()"
                                                            ><v-icon>mdi-minus</v-icon></v-btn>
                                                            <v-btn
                                                                class="quadro"
                                                                color="primary"
                                                                small
                                                                @click="set.increaseDuration()"
                                                            ><v-icon>mdi-plus</v-icon></v-btn>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group-horizontal-cols">
                                                <div class="form-group-horizontal">
                                                    <div class="horizontal-label">{{ t('workout_set_label_glossary') }}</div>
                                                    <div class="horizontal-input">
                                                        <v-autocomplete
                                                            v-model="set.definitionIds"
                                                            :items="definitions.data"
                                                            item-text="name"
                                                            item-value="id"
                                                            required
                                                            outlined
                                                            append-icon="mdi-chevron-down"
                                                            dense
                                                            multiple
                                                            :error-messages="form.errors.get(`sets.${index}.definitions`)"
                                                        ></v-autocomplete>
                                                    </div>
                                                </div>
                                                <div class="form-group-horizontal">
                                                    <template v-if="set.interval === 'classic'">
                                                        <div class="horizontal-label">{{ t('workout_set_label_rest_time') }}</div>
                                                        <div class="horizontal-input">
                                                            <v-text-field
                                                                class="numbar float-left"
                                                                v-model="set.restTime"
                                                                v-maska="'##:##'"
                                                                type="text"
                                                                required
                                                                outlined
                                                                dense
                                                                :error-messages="form.errors.get(`sets.${index}.rest_time`)"
                                                            ></v-text-field>
                                                            <div class="float-right ml-5 mt-3-1">
                                                                <v-btn
                                                                    class="quadro mr-1"
                                                                    color="primary"
                                                                    small
                                                                    @click="set.reduceRest()"
                                                                ><v-icon>mdi-minus</v-icon></v-btn>
                                                                <v-btn
                                                                    class="quadro"
                                                                    color="primary"
                                                                    small
                                                                    @click="set.increaseRest()"
                                                                ><v-icon>mdi-plus</v-icon></v-btn>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </div>
                                            </div>
                                            <div class="form-group-horizontal-cols">
                                                <div class="form-group-horizontal"></div>
                                                <div class="form-group-horizontal">
                                                    <div class="horizontal-label"></div>
                                                    <div class="horizontal-input pt-2">
                                                        <v-btn
                                                            @click="delSet(index)"
                                                            class="exs mr-3"
                                                            color="orange"
                                                            small
                                                        >{{ t('workout_set_button_delete_set') }}</v-btn>
                                                        <v-menu
                                                            offset-y
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    class="exs mr-3"
                                                                    color="primary"
                                                                    small
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                >{{ t('general_action_move') }}</v-btn>
                                                            </template>
                                                            <v-list dense>
                                                                <v-list-item
                                                                    :key="`set_card_item_${index}_${i}`"
                                                                    @click="updateSetNumber(index, i)"
                                                                    v-for="(v, i) in workout.sets"
                                                                    :disabled="index === i"
                                                                    link
                                                                >
                                                                    <v-list-item-title>{{ i + 1 }}</v-list-item-title>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-menu>
                                                        <v-btn
                                                            @click="duplicateSet(index)"
                                                            class="exs mr-5"
                                                            color="primary"
                                                            outlined
                                                            small
                                                        >{{ t('general_action_duplicate') }}</v-btn>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-form>
                                    </v-card-text>
                                </v-card>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-tabs>
                </v-form>
            </v-card-text>

            <v-card-actions class="px-4">
                <v-btn
                    :to="{ name: 'workouts' }"
                    class="mr-4"
                    color="primary"
                >{{ t('general_action_cancel') }}</v-btn>
                <v-btn
                    :loading="form.busy"
                    :disabled="form.busy"
                    color="orange"
                    @click.prevent="submit"
                >{{ workout.exists() ? t('general_action_save') : t('workout_title_create') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-layout>
</div>
